import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchImages } from "../../actions";

import { Button, Icon } from "semantic-ui-react";

import ImageModal from "./imageModal";
const AddImageModal = ({ update_image_selected }) => {
  const dispatch = useDispatch();
  const images = useSelector((state) => state.images);
  const [modal_open, update_modal_open] = useState(false);

  useEffect(() => {
    dispatch(fetchImages());
  }, []);

  return (
    <div>
      <Button primary onClick={() => update_modal_open(true)}>
        <Icon name="image" />
        Add Image
      </Button>
      <ImageModal
        modal_open={modal_open}
        update_modal_open={update_modal_open}
        image_selected={update_image_selected}
      />
    </div>
  );
};

export default AddImageModal;
