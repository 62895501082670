import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchImages } from "../../../actions";

import {
  Segment,
  Checkbox,
  Divider,
  Card,
  Image,
  Button,
} from "semantic-ui-react";
import UploadModal from "../../../libs/UploadModal";
import AddImageModal from "../../../components/imageManagement/AddImageModal";
import ImageBlock from "../../../components/imageManagement/imageBlock";

const ImageSection = ({
  section,
  add_image_to_section,
  uploadModalOpen,
  update_image_style,
  image_style,
  excludeInline,
  includePreview,
  nullImage,
}) => {
  const dispatch = useDispatch();
  const images = useSelector((state) => state.images);

  const [selected_image, update_selected_image] = useState(null);

  useEffect(() => {
    dispatch(fetchImages());
  }, []);

  function image_selected(chosen_image) {
    try {
      console.log(chosen_image);
      update_selected_image(chosen_image);
      add_image_to_section(chosen_image);
    } catch (error) {
      console.log(error);
    }
  }

  function update_style() {
    console.log("try to toggle");
    console.log(image_style);
    if (image_style === "inline") {
      update_image_style("full");
      return;
    }
    update_image_style("inline");
  }
  return (
    <Segment secondary>
      <div style={{ textAlign: "center" }}>
        <AddImageModal images={images} update_image_selected={image_selected} />

        {excludeInline ? (
          <></>
        ) : (
          <div>
            <Divider />
            <h4>Inline?</h4>
            <Checkbox
              toggle
              onChange={() => update_style()}
              checked={image_style === "inline"}
            />
            <Divider />
            <div>Image selected</div>
            <div>{JSON.stringify(!!section?.image_url)}</div>
            <Button onClick={() => nullImage()}>Clear Image</Button>
          </div>
        )}
        {includePreview ? (
          <div style={{ textAlign: "center", margin: "auto" }}>
            {" "}
            <Card>
              <Image
                syle={{
                  border: "none",
                  boxShadow: "none",
                  borderWidth: 0,
                  borderColor: "#ffffff",
                  borderBottom: "none",
                }}
                src={section.image_url}
                // key={section.id}
                // label="asd"
                // inline={true}
                // onClick={() => image_selected(image)}
              />
            </Card>
          </div>
        ) : (
          <></>
        )}
      </div>
    </Segment>
  );
};

export default ImageSection;
