import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API, graphqlOperation } from "aws-amplify";
import shorthash from "shorthash";
import { Button, Icon, Card, Image, Segment, Form } from "semantic-ui-react";
import _ from "lodash";
import ImageBlock from "./imageBlock";
import ImageModal from "./imageModal";
import { fetchImages, update_image, removeImage } from "../../actions";
import { processImage } from "../../graphql/queries";
import UploadImageSection from "./UploadImage";
import UploadModal from "../../libs/UploadModal";
import moment from "moment";
import s3config from "../../s3config";
import { s3Upload } from "../../libs/awsLib";

const ProcessingImages = () => {
  const dispatch = useDispatch();
  const images = useSelector((state) => state.images);
  const state = useSelector((state) => state);
  const [image_size, update_image_size] = useState(400);
  const [selected_image, update_selected_image] = useState(null);
  const [modal_open, update_modal_open] = useState(false);
  const timestamp = moment().unix();
  const [uploadModalOpen, update_uploadModalOpen] = useState(false);

  function resetVersionId() {
    console.log("resetting version ids");
    images.map((img) => {
      const params = {
        version_id: img.version_id + "122",
        id: img.id,
      };
      dispatch(update_image(params));
    });
  }

  async function upload(event) {
    try {
      console.log("running upload function");
      update_uploadModalOpen(true);
      const file = event.target.files[0];
      console.log(file);

      const newFile = await s3Upload(file);
      console.log(newFile);
      const public_url = s3config.S3.URL + s3config.S3.EXT + newFile;
      const hash = shorthash.unique(public_url);
      console.log(public_url);

      const response = await API.graphql(
        graphqlOperation(processImage, {
          image: JSON.stringify({
            params: {},
            size: 400,
            url: public_url,
          }),
        })
      );
      const parsed_response = JSON.parse(response.data.processImage);
      const parsed_response_body = JSON.parse(parsed_response.body);
      const new_url = JSON.parse(parsed_response_body);
      const params = {
        id: selected_image.id,
        image_url: new_url,
        original_image: public_url,
        version_id: shorthash.unique(new_url),
      };
      console.log(params);
      dispatch(update_image(params));
      update_uploadModalOpen(false);
    } catch (error) {
      console.log(error);
    }
  }

  function deleteImage() {
    dispatch(removeImage(selected_image.id));
  }

  function image_selected(chosen_image) {
    try {
      console.log(chosen_image);

      update_selected_image(chosen_image);
      update_modal_open(false);
      //runProcessImage(selected_image);
    } catch (error) {
      console.log(error);
    }
  }

  function returnSelectedImage() {
    if (selected_image) {
      return (
        <div>
          <h4>Image used (processed)</h4>
          <Card>
            <Image
              syle={{
                border: "none",
                boxShadow: "none",
                borderWidth: 0,
                borderColor: "#ffffff",
                borderBottom: "none",
              }}
              src={selected_image.image_url}
              key={selected_image.id}
              // label="asd"
              inline={true}
              // onClick={() => image_selected(image)}
            />
          </Card>
          {/* <div>{selected_image.image_url}</div> */}
          <h4>Original Image </h4>
          <Card>
            <Image
              syle={{
                border: "none",
                boxShadow: "none",
                borderWidth: 0,
                borderColor: "#ffffff",
                borderBottom: "none",
              }}
              src={selected_image.original_image}
              key={selected_image.id}
              // label="asd"
              inline={true}
              // onClick={() => image_selected(image)}
            />
          </Card>
          <div>
            <div>Hash: {selected_image.hash}</div>
            <div>Version ID: {selected_image.version_id}</div>
            {/* <div>Tags: {selected_image.tags}</div> */}
          </div>
        </div>
      );
    }
    return <div>Please select and image to edit</div>;
  }

  async function runProcessImage() {
    console.log("trying to process");
    console.log(typeof image_size);

    // if orginal image null save the original first.
    if (selected_image.original_image === null) {
      const params = {
        original_image: selected_image.image_url,
        id: selected_image.id,
      };
      dispatch(update_image(params));
      return;
    }
    // if orginal image not null, then we can try getting low res vesion
    console.log(selected_image.original_image);
    const response = await API.graphql(
      graphqlOperation(processImage, {
        image: JSON.stringify({
          params: selected_image,
          size: Number(image_size),
          url: selected_image.original_image,
        }),
      })
    );
    console.log(response);
    const parsed_response = JSON.parse(response.data.processImage);
    const parsed_response_body = JSON.parse(parsed_response.body);
    const new_url = JSON.parse(parsed_response_body);
    const params = {
      image_url: new_url,
      id: selected_image.id,
      version_id: shorthash.unique(new_url),
    };
    console.log(params);
    dispatch(update_image(params));
    return;
  }

  // function fixHash() {
  //   dispatch(update_image({ id: selected_image.id, hash: "ZqPdF1" }));
  // }

  useEffect(() => {
    dispatch(fetchImages());
  }, []);

  useEffect(() => {
    console.log("updating selected image useEffect");
    const new_selected_image_object = { ...selected_image };
    update_selected_image(_.find(images, { id: new_selected_image_object.id }));
  }, [images]);

  function returnIfSelected() {
    return (
      <div>
        <Segment>
          <h4>Reprocess Image quality</h4>
          <p>
            Most images are processed to around 400px wide, but you can choose
            to increase or decrease this below and select 'Process Image'
          </p>
          <Form>
            <Form.Input
              style={{ width: 150, paddingBottom: 20 }}
              label="Image Width"
              placeholder="Enter Width"
              value={image_size}
              type="number"
              onChange={(e) => update_image_size(e.target.value)}
            />
          </Form>
          <Button primary onClick={() => runProcessImage()}>
            Process Image
          </Button>
        </Segment>
        <Segment>
          <h4>Replace Image</h4>
          <Segment secondary>
            <div key={timestamp} style={{ textAlign: "center" }}>
              {/* <h5 style={{ marginBottom: 5 }}>Upload image:</h5> */}
              <label for={"file:" + timestamp} className="ui icon button teal">
                <i className="file icon"></i>
                Replace Image
              </label>
              <input
                type="file"
                id={"file:" + timestamp}
                accept="image/png, image/jpeg"
                onChange={upload}
                style={{ display: "none" }}
                key={timestamp}
              />
              <UploadModal uploadModalOpen={uploadModalOpen} />
            </div>
          </Segment>
          {/* <Button primary onClick={() => replaceImage()}>
          Replace Image
        </Button> */}
        </Segment>
        <Segment>
          <Button primary onClick={() => deleteImage()}>
            Delete Image
          </Button>
        </Segment>
      </div>
    );
  }

  return (
    <div>
      <Segment>
        <h4>Select the image you want to edit</h4>

        <Button primary onClick={() => update_modal_open(true)}>
          <Icon name="image" />
          Add Image
        </Button>
        <ImageModal
          modal_open={modal_open}
          update_modal_open={update_modal_open}
          image_selected={image_selected}
        />
      </Segment>
      <Segment>
        <h4>Selected Image</h4>
        <div>{returnSelectedImage()}</div>
      </Segment>

      {/* <div>{JSON.stringify(selected_image)}</div> */}
      {selected_image ? <>{returnIfSelected()}</> : <></>}

      {/* <ImageBlock images={images} image_selected={image_selected} /> */}
    </div>
  );
};

export default ProcessingImages;
